import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

const redirectUnauth = () => redirectUnauthorizedTo(['auth', 'login']);

const routes: Routes = [
	{
		path: '', component: AppLayoutComponent, ...canActivate(redirectUnauth),
		children: [
			{ path: '', data: {breadcrumb: 'Home'}, loadChildren: () => import('./site/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
			{ path: 'apps',  data: { breadcrumb: 'Patient Management' }, loadChildren: () => import('./site/components/patient-mgmt/patient-mgmt.module').then(m => m.AppsModule) },
      { path: 'clinician-mgmt',  data: { breadcrumb: 'Clinician Management' }, loadChildren: () => import('./site/components/clinician-mgmt/clinician-mgmt.module').then(m => m.ClinicianMgmtModule) },
			{ path: 'mgmt', data: {breadcrumb: 'Session Management'}, loadChildren: () => import('./site/components/billing/billing.module').then(m => m.BillingModule) },
			{ path: 'admin',  data: { breadcrumb: 'Administration' }, loadChildren: () => import('./site/components/administration/administration.module').then(m => m.AdministrationModule) },
		]
	},
	{ path: 'auth', data: {breadcrumb: "Auth"}, loadChildren: () => import('./site/components/auth/auth.module').then(m => m.AuthModule) },
	{ path: "**", redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
