export const MenuItems = [
	{
		label: 'Dashboard',
		icon: 'pi pi-home',
		canActivate: ['staff', 'admin', 'clinician'],
		items: [
			{
				label: 'Home',
				icon: 'pi pi-fw pi-home',
				routerLink: ['/'],
				canActivate: ['staff', 'admin', 'clinician']
			},
		]
	},
	{
		label: 'Patient Management',
		icon: 'pi pi-th-large',
		canActivate: ['staff', 'admin', 'clinician'],
		items: [
			{
				label: 'Patients',
				icon: 'pi pi-fw pi-users',
				routerLink: ['/apps/patients'],
				canActivate: ['staff', 'admin', 'clinician']
			},
			{
				label: 'Applications',
				icon: 'pi pi-fw pi-clock',
				routerLink: ['/apps/apps'],
				canActivate: ['staff', 'admin']
			},
			{
				label: 'Assignments',
				icon: 'pi pi-fw pi-clock',
				routerLink: ['/apps/assignments'],
				canActivate: ['clinician'],
				badge: 'pending-approval'
			},
			{
				label: 'Assign',
				icon: 'pi pi-fw pi-check',
				routerLink: ['/apps/assign'],
				canActivate: ['admin', 'staff'],
				badge: 'pending-matching'
			},
		]
	},
  {
    label: 'Clinician Management',
    icon: 'pi pi-th-large',
    canActivate: ['staff', 'admin'],
    items: [
      {
        label: 'Clinicians',
        icon: 'pi pi-fw pi-users',
        routerLink: ['/clinician-mgmt/clinicians'],
        canActivate: ['staff', 'admin']
      },
      {
        label: 'Applications',
        icon: 'pi pi-fw pi-clock',
        routerLink: ['/clinician-mgmt/apps'],
        canActivate: ['staff', 'admin']
      },
    ]
  },
	{
		label: "Session Management",
		icon: "pi pi-dollar",
		canActivate: ['staff', 'admin', 'clinician'],
		items: [
			{
				label: 'Sessions',
				icon: "pi pi-paperclip",
				routerLink: ['/mgmt/sessions'],
				canActivate: ['clinician']
			},
			{
				label: 'Sessions',
				icon: "pi pi-briefcase",
				routerLink: ['/mgmt/claims'],
				canActivate: ['staff', 'admin']
			}
		]
	},
	{
		label: 'Administration',
		icon: 'pi pi-th-large',
		canActivate: ['admin'],
		items: [
			{
				label: 'Analytics',
				icon: 'pi pi-fw pi-chart-bar',
				routerLink: ['/admin/analytics'],
				canActivate: ['admin']
			},
			{
				label: 'User Management',
				icon: 'pi pi-fw pi-users',
				routerLink: ['/admin/users'],
				canActivate: ['admin']
			},
		]
	},
];
