export const environment = {
	production: false,
	config: {
		apiKey: "AIzaSyB7XO9DZf0ThNqCSOQgBDZhzY3qTMcXZM8",
		authDomain: "blackmenheal-nprd.firebaseapp.com",
		projectId: "blackmenheal-nprd",
		storageBucket: "blackmenheal-nprd.appspot.com",
		messagingSenderId: "756012963917",
		appId: "1:756012963917:web:a370723ece7f73db48e404"
	},
	API_URL: 'https://blackmenheal-nprd.uk.r.appspot.com',
	storage_bucket: 'https://storage.googleapis.com/blackmenheal-nprd.appspot.com',
	bucket_name: 'blackmenheal-nprd.appspot.com',
  docs_bucket: 'https://storage.googleapis.com/blackmenheal-nprd-docs',
  docs_bucket_name: 'blackmenheal-nprd-docs',
};
