import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private db: AngularFirestore) { }

  async addAuditLog(email: string, msg: string) {
	await this.db.collection("auditLogs").add({
		email: email,
		message: msg,
		ts: new Date().getTime()
	})
  }
}
