import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Patient } from '../api/patient';
import { HttpClient } from '@angular/common/http';
import { AuditService } from './audit.service';
import { combineLatest, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitlistService {

	constructor(private db: AngularFirestore, private http: HttpClient, private audit: AuditService) { }

	getClinicianWaitlist() {
	  return this.db.collection('waitlists').valueChanges();
	}

	getClinicianWaitlistById(clinicianId: string) {
		return this.db.collection('waitlists', ref => ref.where('clinician', '==', clinicianId).orderBy('ts')).valueChanges();
	  }

	getPendingClinicianWaitlist() {
		return this.db.collection('pendingAssignment', ref => ref.orderBy('ts')).valueChanges();
	}

	getHistory() {
		return this.db.collection('history').valueChanges();
	}

	getNotifies() {
		return this.db.collection("notifies").valueChanges();
	}

	getNotifiesByNotifyId(notifyId: string) {
		return this.db.collection("notifies", ref => ref.where('notifyId', '==', notifyId)).valueChanges();
	}

	getPendingAssignment(clinicianId: string) {
		return this.db.collection("pendingAssignment", ref => ref.where('clinician', '==', clinicianId)).valueChanges();
	}

	async getNotifyId(clinicianId: string) {
		let h = await this.db.collection("history", ref => ref.where('fulfilled', '==', false).where('clinicianId', '==', clinicianId).limit(1)).get().toPromise();
		let d: any = h?.docs[0].data()
		return d.notifyId;
	}

	getCurrentlyNotified() {
		return this.db.collection("history", ref => ref.where('fulfilled', '==', false)).valueChanges();
	}

	// async getNotifiesByNotifyId(notifyId: string) {
	// 	let n = await this.db.collection("notifies", ref => ref.where('notifyId', '==', notifyId)).get().toPromise();
	// 	return n?.docs.map((doc) => doc.data());
	// }

	async removePatientFromClinicianWaitlist(key: string) {
		await this.db.collection("waitlists").doc(key).delete();
	}

	async removePatientFromPendingClinWaitlist(key: string) {
		await this.db.collection("pendingAssignment").doc(key).delete();
	}

	// async canAssignToClinician(patientId: string, clinicianId: string) {
	// 	let h = await this.db.collection("waitlists", ref => ref.where('patient', '==', patientId).limit(1)).get().toPromise();
	// 	let d: any = h?.docs[0].data()
	// 	let rejected = d.rejected;
	// 	if (typeof rejected === 'undefined' || (typeof rejected === 'object' && rejected.length === 0)) {
	// 		return true;
	// 	}
	// 	for (let r of rejected) {
	// 		if (r.clinician === clinicianId) {
	// 			return false;
	// 		}
	// 	}
	// 	return true;
	// }

	// async getRejectionReason(patientId: string, clinicianId: string) {
	// 	let h = await this.db.collection("waitlists", ref => ref.where('patient', '==', patientId).limit(1)).get().toPromise();
	// 	let d: any = h?.docs[0].data()
	// 	let rejected = d.rejected;
	// 	for (let r of rejected) {
	// 		if (r.clinician === clinicianId) {
	// 			return r.reason;
	// 		}
	// 	}
	// 	return "N/A";
	// }


	async addRejectedClinPending(patientId: string, clinicianId: string, rejectReason: string) {
		let h = await this.db.collection("waitlists", ref => ref.where('patient', '==', patientId).limit(1)).get().toPromise();
		let d: any = h?.docs[0].data()
		let rejected = d.rejected;
		if (!rejected || typeof rejected === 'undefined') {
			rejected = [];
		}
		rejected.push({
			clinician: clinicianId,
			reason: rejectReason,
			ts: new Date().getTime()
		});
		await this.db.collection('waitlists').doc(patientId).update({
			rejected: rejected
		});
	}

	async addPatientsToPendingClinWaitlist(keys: Object, clinicianId: string, modifiedBy: string) {
		for (let [patientId, patientName] of Object.entries(keys)) {
			await this.db.collection("pendingAssignment").doc(patientId).set({
				clinician: clinicianId,
				modifiedBy: modifiedBy,
				patient: patientId,
				patientName: patientName,
				ts: new Date().getTime()
			})
		}
	}

	async cancelNotify(notifyId: string, email: string) {
		await this.db.collection("history").doc(notifyId).update({
			fulfilled: true,
			patientId: "cancelled",
			modifiedBy: email,
			modified: new Date().getTime()
		})
		await this.audit.addAuditLog(email, `Cancelled notify ${notifyId}`);
	}

	async markNotifyAsLoaded(notifyId: string, email: string) {
		await this.db.collection("history").doc(notifyId).update({
			isLoaded: true,
			modifiedBy: email,
			modified: new Date().getTime()
		})
		await this.audit.addAuditLog(email, `Marked appointment as loaded: ${notifyId}`);
	}

	async rmFromPendingClin(patient: Patient) {
		await this.db.collection('pendingAssignment').doc(patient.partitionKey).delete();
	}

	async moveFromPendingClinToWaitlist(patient: Patient, clinicianId: string, modifiedBy: string) {
		await this.db.collection('pendingAssignment').doc(patient.partitionKey).delete();

		await this.db.collection('waitlists').doc(patient.partitionKey).set({
			patient: patient.partitionKey,
			clinician: clinicianId,
			ts: new Date().getTime(),
			modifiedBy: modifiedBy,
			patientName: patient.firstName + " " + patient.lastName,
		});
	}

	async moveFromPendingClinToPending(patient: Patient, modifiedBy: string) {
		await this.db.collection('pendingAssignment').doc(patient.partitionKey).delete();
		
		await this.db.collection('waitlists').doc(patient.partitionKey).set({
			patient: patient.partitionKey,
			clinician: 'pending',
			ts: new Date().getTime(),
			modifiedBy: modifiedBy,
			patientName: patient.firstName + " " + patient.lastName,
		})
	}

	getPendingPatientsByClin(clinicianId: string) {
		return this.db.collection('pendingAssignment', ref => ref.where('clinician', '==', clinicianId))
		.valueChanges()
		.pipe(
		  map(assignments => assignments.map((a: any) => a.patient)),
		  switchMap(patientUUIDs => {
			// If there are no patients, return an empty array immediately.
			if (patientUUIDs.length === 0) {
			  return of([]);
			}
  
			// Split the patient UUIDs into chunks of size 30
			// const chunks = this.chunkArray(patientUUIDs, 30);
  
			// Create a query for each chunk
			const queries$ = patientUUIDs.map((id: any) => {
			  return this.db.collection('patients').doc(id).valueChanges();
			});
  
			// Combine the results of all queries
			return combineLatest(queries$).pipe(
			  map((arrays: any) => [].concat.apply([], arrays)) // Flatten the array of arrays
			);
		  })
		);
	}
}
