import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {REGION} from "@angular/fire/compat/functions";


@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AppLayoutModule,
		AngularFireModule.initializeApp(environment.config),
		AngularFirestoreModule,
		AngularFireAuthModule,
		AngularFireAuthGuardModule,
		HttpClientModule
	],
	
	bootstrap: [AppComponent],
  providers: [ {provide: REGION, useValue: 'us-east4'} ]
})
export class AppModule { }
