import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Claims } from '../api/claims';
import { AuditService } from './audit.service';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ClinicianReassertion} from "../api/clinicianReassertion";

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	user: any;
	claims?: Claims;
	token: any;

	constructor(private db: AngularFirestore, private afAuth: AngularFireAuth, private router: Router, private audit: AuditService) {
		this.afAuth.authState.subscribe((user) => {
			this.user = user;
			if (!this.claims) {
				this.user.getIdTokenResult()
					.then((idTokenResult: any) => {
						this.claims = idTokenResult.claims;
						this.token = idTokenResult.token;
					})
					.catch(async (error: any) => {
						console.log('Error getting user claims:', error);
						await this.SignOut();
					});
			}
		})
	}

	async SignIn(email: string, password: string, persistence: string) {
		try {
			const result = await this.afAuth.signInWithEmailAndPassword(email, password);
			await this.afAuth.setPersistence(persistence)
			this.user = result.user;
			await this.audit.addAuditLog(email, `Logged in`);
			this.user.getIdTokenResult(true)
				.then((idTokenResult: any) => {
					this.claims = idTokenResult.claims;
				})
				.catch(async (error: any) => {
					console.log('Error getting user claims:', error);
					await this.SignOut();
				});
		} catch (err) {
			throw err;
		}
	}

	async ForgotPassword(email: string) {
		await this.afAuth.sendPasswordResetEmail(email)
	}

	async SignOut() {
		this.afAuth.signOut().then(() => {
			this.user = null;
			this.claims = undefined;
			this.token = null;
			this.router.navigate(['auth', 'login']);
		});
	}

	getAuth(): Observable<any> {
		return this.afAuth.authState;
	}

	isLoggedIn(): Observable<boolean> {
		return this.afAuth.authState.pipe(
			map(user => !!user)
		);
	}

  requiresReassert() {
    return new Observable<boolean>((observer) => {
      this.getClaims().subscribe((claims) => {

        if (claims.role === 'clinician') {
          let reassertDoc = this.db.collection("clinicianReassertions",
            ref => ref
              .where('clinicianId', '==', claims.clinicianId)
              .limit(1))
            .valueChanges();

          reassertDoc.subscribe((data: any) => {
            if (data.length > 0) {
              data = data[0] as ClinicianReassertion;
              console.log("reassert value is " + data.reassert);

              if (data.reassert) {
                observer.next(true);
              }
              // It could be undefined, if the record does not exist. In this case, we return false.
              else {
                observer.next(false);
              }
              return;
            }
            // No matching reassert doc found.
            else {
              observer.next(false);
              return;
            }
          })
        } else {
          observer.next(false);
          return;
        }
      })

    })
  }

	getCurrentUser() {
		return this.user
	}

	setCurrentUser(user: any) {
		this.user = user;
	}

	getToken() {
		return this.token;
	}

	getAuthToken(): Observable<string> {
		return new Observable<string>((observer) => {
			if (this?.token) {
				observer.next(this.token);
				return;
			}
			this.afAuth.authState.subscribe(async (user) => {
				if (user) {
					this.user = user;
					user.getIdTokenResult(true)
						.then((idTokenResult: any) => {
							this.claims = idTokenResult.claims;
							observer.next(idTokenResult.token);
							observer.complete();
						})
						.catch(async (error: any) => {
							console.log('Error getting user token:', error);
							await this.SignOut();
							observer.error();
						})
				} else {
					console.log('Error getting user');
					await this.SignOut();
					observer.error();
				}
			})
		})
	}

	getClaims(): Observable<Claims> {
		return new Observable<Claims>((observer) => {
			if (this?.claims) {
				observer.next(this.claims);
				return;
			}
			this.afAuth.authState.subscribe(async (user) => {
				if (user) {
					this.user = user;
					user.getIdTokenResult()
						.then((idTokenResult: any) => {
							this.claims = idTokenResult.claims;
							observer.next(idTokenResult.claims)
							observer.complete()
						})
						.catch(async (error: any) => {
							console.log('Error getting user claims:', error);
							await this.SignOut();
							observer.error();
						})
				} else {
					console.log('Error getting user:');
					await this.SignOut();
					observer.error();
				}
			})
		})
	}

	getRoleAndId(): Observable<Array<string>> {
		return new Observable<Array<string>>((observer) => {
			if (this?.claims && this.claims?.role) {
				// @ts-ignore
				observer.next([this.claims.role, this.claims.role == 'clinician' ? this.claims.clinicianId : null]);
				return;
			}
			this.afAuth.authState.subscribe((user) => {
				if (user) {
					user.getIdTokenResult()
						.then((idTokenResult: any) => {
							observer.next([idTokenResult.claims.role, idTokenResult.claims.role == 'clinician' ? idTokenResult.claims.clinicianId : null]);
							observer.complete();
						})
						.catch((error: any) => {
							console.log('Error getting user claims:', error);
							observer.error();
						});
				} else {
					console.log('Error getting user:');
					observer.error();
				}
			})
		})
	}
}
