import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { MenuItems } from './api/menuitems';
import { FilterService } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthService } from '../site/service/auth.service';
import { Claims } from '../site/api/claims';
import { environment } from 'src/environments/environment';

interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    @ViewChild('menubutton') menuButton!: ElementRef;
	suggestions: any[] = [];
	filteredGroups: any[] = [];
	input: string = "";

	profile_img: string = `${environment.storage_bucket}/unknown.jpeg`;

    constructor(public layoutService: LayoutService, private filterService: FilterService, private router: Router,
				private authService: AuthService) { }

	ngOnInit() {
		this.suggestions = MenuItems;
		this.filteredGroups = [];

		this.authService.getClaims().subscribe((data: Claims) => {
			if (data?.img_url) {
				this.profile_img = data?.img_url;
			}
		})
	}

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

	filterSearch(event: AutoCompleteCompleteEvent) {
		let query = event.query;
        let filteredGroups = [];

        for (let optgroup of this.suggestions) {
            let filteredSubOptions = this.filterService.filter(optgroup.items, ['label'], query, "contains");
            if (filteredSubOptions && filteredSubOptions.length) {
                filteredGroups.push({
                    label: optgroup.label,
                    value: optgroup.value,
                    items: filteredSubOptions
                });
            }
        }


        this.filteredGroups = filteredGroups;
	}
    
	select(val: any) {
		if (val?.routerLink) {
			this.router.navigate(val.routerLink);
		} else {
			val.command();
		}
		this.input = "";
	}
}