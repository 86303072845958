import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '../site/service/auth.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {

    constructor(public layoutService: LayoutService, private router: Router, private authService: AuthService) { }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

	getName() {
		let u = this.authService.getCurrentUser();
		if (!u) return '';
		return u.displayName;
	}

  async viewProfile() {
      let claims = this.authService.getClaims();
      claims.subscribe((claims)  => {
        if (claims.role === 'clinician')  {
          this.router.navigate(['clinician-mgmt', 'reassert', claims.clinicianId]);
        }
      })
	  this.visible = false;
  }
	async logout() {
		await this.authService.SignOut();
	}
}
