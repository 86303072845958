<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="topbar-search">
				<p-autoComplete [(ngModel)]="input" [forceSelection]="true" placeholder="Search" [group]="true" [suggestions]="filteredGroups" class="w-12rem sm:w-full" (completeMethod)="filterSearch($event)" (onSelect)="select($event)" field="label">
					<ng-template let-group pTemplate="group">
						<div class="flex align-items-center">
							<span>{{group.label}}</span>
						</div>
					</ng-template>
				</p-autoComplete>
            </li>
            <li class="topbar-profile">
                <button type="button" class="p-link" (click)="onProfileButtonClick()">
                    <img [src]="profile_img" class="border-circle" alt="Profile" />
                </button>
            </li>
        </ul>
    </div>
</div>